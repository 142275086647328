import React, { useCallback, useState, useEffect } from 'react'
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
  useHistory
} from 'react-router-dom'

import DefaultLayout from '../pages/_layouts/default'

import { useAuth } from '../hooks/auth'

import { useToast } from '../hooks/toast'
import { Loading } from '../components/Loading'
import { UnauthenticatedRouteError } from '../components/UnauthenticatedRouteError'
import { match } from 'path-to-regexp'
import { NOT_ALLOWED_PARTNER } from 'common/constants/permissions'
import {
  LOCAL_STORAGE_KEYS,
  ROLE_OPERATOR_ID,
  ROLE_PARTNER_ID
} from 'common/constants'

interface RouteProps extends ReactDOMRouteProps {
  component: React.ComponentType
}

const PrivateRoutes: React.FC<RouteProps> = ({
  path,
  component: Component,
  ...rest
}) => {
  const [permissions, setPermissions] = useState(false)
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  const { userLogged, user, setHistory } = useAuth()
  const { addToast } = useToast()
  const Layout = DefaultLayout

  const handleSetPermissions = useCallback(async () => {
    // /commercial/reports/partners
    if (path) {
      let hasPermission = true
      if ([ROLE_OPERATOR_ID, ROLE_PARTNER_ID].includes(user.role_id)) {
        const routeNotAllowed = !!NOT_ALLOWED_PARTNER.find(route => {
          const urlMatch = match(route, { decode: decodeURIComponent })
          const matchRoute = urlMatch(path as string)
          return matchRoute
        })
        hasPermission = !routeNotAllowed
      }
      // if (path.includes('create')) {
      //   const response = await api.post('checkPermission', {
      //     method: 'POST',
      //     originalUrl: path.toString(),
      //     role_id: user.role_id,
      //     user_id: user.id
      //   })
      //   setPermissions(response.data.approved)
      //   setLoading(false)
      //   return
      // }
      // if (path.includes('update')) {
      //   const response = await api.post('checkPermission', {
      //     method: 'PUT',
      //     originalUrl: path.toString(),
      //     role_id: user.role_id,
      //     user_id: user.id
      //   })
      //   setPermissions(response.data.approved)
      //   setLoading(false)
      //   return
      // }
      // if (path.includes('view')) {
      //   const response = await api.post('checkPermission', {
      //     method: 'GET',
      //     originalUrl: path.toString(),
      //     role_id: user.role_id,
      //     user_id: user.id
      //   })
      //   setPermissions(response.data.approved)
      //   setLoading(false)
      //   return
      // }
      // const response = await api.post('checkPermission', {
      //   method: 'GET',
      //   originalUrl: path.toString(),
      //   role_id: user.role_id,
      //   user_id: user.id
      // })

      setPermissions(hasPermission)
      setLoading(false)
    } else {
      setPermissions(true)
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path])

  useEffect(() => {
    if (userLogged()) {
      const forceLogout =
        localStorage.getItem(LOCAL_STORAGE_KEYS.FORCE_LOGOUT) ?? 1
      if (Number(forceLogout)) {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN)
        localStorage.removeItem(LOCAL_STORAGE_KEYS.USER)
        localStorage.removeItem(LOCAL_STORAGE_KEYS.MENUS)
        localStorage.setItem(LOCAL_STORAGE_KEYS.FORCE_LOGOUT, '0')
        history.replace('/')
        return
      }
      handleSetPermissions()
    } else {
      setHistory(path.toString())
    }
  }, [handleSetPermissions, history, path, setHistory, user, userLogged])

  if (!userLogged()) {
    return (
      <ReactDOMRoute
        {...rest}
        render={() => {
          addToast({
            title: 'Pagina não autorizada',
            description:
              'O usuario não tem autorização para acessar esta pagina',
            type: 'error'
          })
          return <Redirect to="/" />
        }}
      />
    )
  }

  if (!loading) {
    if (permissions) {
      return (
        <ReactDOMRoute
          {...rest}
          render={() => {
            return (
              <Layout>
                <Component {...rest} />
              </Layout>
            )
          }}
        />
      )
    } else {
      return (
        <ReactDOMRoute
          {...rest}
          render={() => {
            return <UnauthenticatedRouteError />
          }}
        />
      )
    }
  }
  return <Loading isActive={loading} />
}

export default PrivateRoutes
