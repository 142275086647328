import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { PageLink, PageTitle } from '../../assets/layout/core'
import { Card } from 'components/Card'
import { useToast } from 'hooks/toast'
import api from 'services/api'
import moment from 'moment'
import { MANAGER_ROLES, MASKS, ROLE_PARTNER_TEAM } from 'common/constants'
import { useAuth } from 'hooks/auth'
import Form, { Input } from 'components/Form'
import { handleErrorMessage } from 'utlis/handleErros'
import { cnpjMask, cpfMask, phoneMask } from 'utlis/mask'
import {
  LEMIT_COLUMN_NAME_DDD,
  LEMIT_COLUMN_NAME_NUMBER,
  LEMIT_COLUMN_NAME_WHATSAPP,
  LEMIT_FIELD_PHONE
} from 'common/constants/Commercial/Prospects'
import CustomDataTable from 'components/CustomDataTable'
import { Loading } from 'components/Loading'

const dashboardBreadCrumbs: PageLink[] = [
  {
    title: 'Início',
    path: '/Tela Inícial',
    isSeparator: false,
    isActive: false
  }
]

async function handleVerifyIfNumberHasDoNotDisturb({
  number
}: {
  number: string
}) {
  try {
    const responseDoNotDisturb = await api.post(
      'https://api.cedibra.com/commercial/DoNotDisturb/findByParams',
      {
        phone: number
      }
    )
    return responseDoNotDisturb.data.doNotDisturb
  } catch (error) {}
}
const Dashboard: React.FC = () => {
  const { user } = useAuth()
  const { addToast } = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [distributed, setDistributed] = useState('0')
  const [scheduleToday, setScheduleToday] = useState('0')
  const startMonth = moment().startOf('month').format(MASKS.DATE.ISO)
  const endMonth = moment().endOf('month').format(MASKS.DATE.ISO)
  const [proposals, setProposals] = useState('0')
  const [lemitData, setLemitData] = useState<any[]>([])
  const elementsRef = useRef<any>({})
  const [apisQuery, setApisQuery] = useState<GetCountPerApiParams[]>([
    {
      api_name: 'Lemit',
      quantity: '0'
    },
    {
      api_name: 'Credcesta',
      quantity: '0'
    },
    {
      api_name: 'Fgts',
      quantity: '0'
    },
    {
      api_name: 'Sim',
      quantity: '0'
    }
  ])

  const handleLoadProposals = useCallback(async () => {
    try {
      const responseProposals = await api.post(
        '/commercial/proposals/countProposal'
      )
      setProposals(responseProposals.data.proposals)
    } catch (error) {
      addToast({
        title: 'Erro ao carregar dados',
        description: 'Ocorreu um erro ao carregar os dados',
        type: 'error'
      })
    }
  }, [addToast])
  const handleLoadDistributed = useCallback(async () => {
    try {
      const responseDistributed = await api.post(
        '/commercial/prospectPartners/distributed'
      )
      setDistributed(responseDistributed.data)
    } catch (error) {
      addToast({
        title: 'Erro ao carregar dados',
        description: 'Ocorreu um erro ao carregar os dados',
        type: 'error'
      })
    }
  }, [addToast])

  const handleLoadApisQuery = useCallback(async () => {
    try {
      const responseApisQuery = await api.post(
        '/commercial/prospectsQueriesApis/getCountPerApi'
      )
      setApisQuery(oldApisQuery =>
        oldApisQuery.map(oldApiQuery => {
          const findApiQuery = responseApisQuery.data.find(
            (item: GetCountPerApiParams) =>
              oldApiQuery.api_name.toLocaleLowerCase() ===
              item.api_name.toLocaleLowerCase()
          )
          if (findApiQuery) {
            return {
              ...findApiQuery
            }
          }
          return oldApiQuery
        })
      )
    } catch (error) {
      addToast({
        title: 'Erro ao carregar dados',
        description: 'Ocorreu um erro ao carregar os dados',
        type: 'error'
      })
    }
  }, [addToast])

  const handleLoadScheduleToday = useCallback(async () => {
    try {
      const responseSchedules = await api.post(
        '/commercial/prospectHistories/schedule'
      )
      setScheduleToday(responseSchedules.data)
    } catch (error) {
      addToast({
        title: 'Erro ao carregar dados',
        description: 'Ocorreu um erro ao carregar os dados',
        type: 'error'
      })
    }
  }, [addToast])

  useEffect(() => {
    if (user?.role?.team === ROLE_PARTNER_TEAM) {
      handleLoadScheduleToday()
    }
    if (user?.role?.team !== ROLE_PARTNER_TEAM) {
      handleLoadProposals()
      handleLoadDistributed()
      handleLoadApisQuery()
    }
  }, [
    handleLoadApisQuery,
    handleLoadDistributed,
    handleLoadProposals,
    handleLoadScheduleToday,
    user?.role?.team
  ])

  useEffect(() => {
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])
  const dateOutFormat = 'DD/MM/YYYY'
  const formatsAvailable = useMemo(
    () => [
      'YYYY-MM-DD',
      'YYYY-MM-DD HH:mm:ss',
      'DD/MM/YYYY',
      'DD/MM/YYYY HH:mm:ss',
      'YYYY-MM-DDTHH:mm:ssZ',
      'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'
    ],
    []
  )

  const checkFormatDates = useCallback(
    (value: string) => {
      // eslint-disable-next-line consistent-return
      let formatValue = 'YYYY-MM-DD'
      formatsAvailable.forEach(format => {
        if (moment(value, format, true).isValid()) {
          formatValue = format
        }
      })
      return formatValue
    },
    [formatsAvailable]
  )
  const formatingValues = useCallback(
    ({ key, value: inputValue }: { key: string; value: string | number }) => {
      const fieldsFormating = ['cpf', 'cnpj']
      const value = inputValue.toString()
      const findField = fieldsFormating.find(item => key.includes(item))
      if (inputValue.toString().match(/[0-9]/gi)) {
        if (findField) {
          if (findField === 'cpf') {
            return cpfMask(value)
          }
          if (findField === 'cnpj') {
            return cnpjMask(value)
          }
        }
      }
      if (value === 'true') {
        return 'Sim'
      }
      if (value === 'false') {
        return 'Não'
      }
      const format = checkFormatDates(value)
      if (moment(value, format, true).isValid()) {
        return moment(value, format).format(dateOutFormat)
      }
      return value
    },
    [checkFormatDates]
  )
  const serializedKey = useCallback((value: string) => {
    return value.split('_').join(' ')
  }, [])
  const renderElement = useCallback(
    ({ item, parentKey, parentData }): any => {
      if (item) {
        const dataEntries: any = Object.entries(item)
        for (const [key, value] of dataEntries) {
          if (typeof value === 'object') {
            if (Array.isArray(value)) {
              if (value.length) {
                renderElement({
                  parentData: value,
                  parentKey: serializedKey(key)
                })
              }
            } else {
              renderElement({ item: value, parentKey: serializedKey(key) })
            }
          } else {
            if (parentKey) {
              if (elementsRef.current[parentKey]) {
                elementsRef.current[parentKey] = {
                  ...elementsRef.current[parentKey],
                  child: [
                    ...elementsRef.current[parentKey].child,
                    {
                      title: serializedKey(key),

                      value: formatingValues({ key, value })
                    }
                  ]
                }
              } else {
                elementsRef.current[parentKey] = {
                  title: parentKey,

                  value: 'parent',
                  child: [
                    {
                      title: serializedKey(key),

                      value: formatingValues({ key, value })
                    }
                  ]
                }
              }
            } else {
              elementsRef.current[key] = {
                title: serializedKey(key),

                value: formatingValues({ key, value })
              }
            }
          }
        }
      } else if (parentData) {
        if (parentKey) {
          if (elementsRef.current[parentKey]) {
            elementsRef.current[parentKey] = {
              ...elementsRef.current[parentKey],
              type: 'array',
              child: [...elementsRef.current[parentKey].child, ...parentData]
            }
          } else {
            elementsRef.current[parentKey] = {
              title: parentKey,
              type: 'array',
              value: 'parent',
              child: [...parentData]
            }
          }
        }
      }
      return elementsRef.current
    },
    [formatingValues, serializedKey]
  )

  const onSubmitForm = useCallback(
    async ({ document }: { document: string }) => {
      elementsRef.current = {}
      setIsLoading(true)
      try {
        const responseLemit = await api.post('/apis/lemit/singleQuery', {
          document
        })
        const dataResultElements = renderElement({ item: responseLemit.data })
        const arrayElements = Object.values(dataResultElements) as any[]
        const findPhonesArray = arrayElements.findIndex(
          item => item.title.toLowerCase() === LEMIT_FIELD_PHONE
        )
        if (findPhonesArray !== -1) {
          const newPhoneArray = []
          for (const item of arrayElements[findPhonesArray].child) {
            const responseDoNotDisturb =
              await handleVerifyIfNumberHasDoNotDisturb({
                number: `${item?.[LEMIT_COLUMN_NAME_DDD]}${item?.[LEMIT_COLUMN_NAME_NUMBER]}`
              })
            newPhoneArray.push({ ...item, doNotDisturb: responseDoNotDisturb })
          }
          arrayElements[findPhonesArray].child = [...newPhoneArray]
        }
        setLemitData(arrayElements)
        addToast({
          type: 'success',
          title: 'Consulta concluída com sucesso',
          description: 'Consulta concluída com sucesso'
        })
      } catch (error: any) {
        addToast({
          ...handleErrorMessage(error)
        })
      }
      setIsLoading(false)
    },
    [addToast, renderElement]
  )

  return (
    <>
      <Loading isActive={isLoading} />
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>Tela Inícial</PageTitle>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '2rem 5rem',
          width: '100%'
        }}
      >
        {user?.role?.team === ROLE_PARTNER_TEAM && (
          <Card
            primaryColor="warning"
            text="Agendamentos do dia"
            number={scheduleToday}
            onClick={() => {
              const a = document.createElement('a')
              a.href = `/commercial/prospects?start_date=${startMonth}&end_date=${endMonth}`
              a.click()
              document.removeChild(a)
            }}
            style={{
              cursor: 'pointer'
            }}
            iconSettings={{
              icon: 'fa fa-calendar',
              style: {
                color: '#fff'
              }
            }}
          />
        )}
        {user?.role?.team !== ROLE_PARTNER_TEAM && (
          <>
            <Card
              primaryColor="success"
              text="Distribuídos"
              number={distributed}
              iconSettings={{
                icon: 'fa fa-paper-plane',
                style: {
                  color: '#fff'
                }
              }}
            />
            {apisQuery.map(item => {
              return (
                <Card
                  key={item.api_name}
                  primaryColor="success"
                  text={'Consultas ' + item.api_name}
                  number={item.quantity}
                  iconSettings={{
                    icon: 'fa fa-magnifying-glass',
                    style: {
                      color: '#fff'
                    }
                  }}
                />
              )
            })}
            <Card
              primaryColor="primary"
              text="Propostas aprovadas"
              number={proposals}
              iconSettings={{
                icon: 'fa fa-handshake',
                style: {
                  color: '#fff'
                }
              }}
            />
          </>
        )}
      </div>
      {!!(
        !MANAGER_ROLES.includes(user?.role_id) &&
        user?.partner?.crm_query_lemit &&
        user?.role?.team?.toLowerCase() === 'p'
      ) && (
        <div className="card mt-10">
          <div className="card-body p-9">
            <h2>Consultar no lemit</h2>
            <Form onSubmit={onSubmitForm}>
              <div className="mb-5">
                <Input
                  name="document"
                  label="CPF"
                  className="col-md-3"
                  controlled
                  mask={{ mask: MASKS.DOCUMENT.CPF, type: 'cpf' }}
                />
              </div>
              <div className="card-footer d-flex justify-content-end py-6 ps-9 pe-0">
                <button type="submit" className="btn btn-primary">
                  Consultar
                </button>
              </div>
            </Form>

            <div className="mt-5">
              {lemitData?.map?.(
                (item: {
                  child: any[]
                  title:
                    | boolean
                    | React.ReactChild
                    | React.ReactFragment
                    | React.ReactPortal
                  type: string
                  value:
                    | boolean
                    | React.ReactChild
                    | React.ReactFragment
                    | React.ReactPortal
                }) => {
                  const hasSubItems = !!item?.child?.length
                  const subItems = item?.child
                  const subItemsIsObject = item?.child?.every(
                    (subItem: any) => typeof subItem === 'object'
                  )
                  const subHeaders = hasSubItems
                    ? Object.keys(subItems[0])
                        .filter(
                          key =>
                            key.toLocaleLowerCase() !== LEMIT_COLUMN_NAME_DDD &&
                            key.toLocaleLowerCase() !== 'donotdisturb'
                        )
                        .map(key => {
                          if (
                            key.toLocaleLowerCase() === LEMIT_COLUMN_NAME_NUMBER
                          ) {
                            if (item?.child[0]?.[LEMIT_COLUMN_NAME_WHATSAPP]) {
                              return {
                                name: serializedKey(key),
                                field: key,
                                sortable: true,
                                custom: true
                              }
                            }
                          }
                          return {
                            name: serializedKey(key),
                            field: key,
                            sortable: true
                          }
                        })
                    : []
                  return (
                    <div key={Math.random() * 1000 + 1} className="row mb-2">
                      <h1 style={{ textTransform: 'capitalize' }}>
                        {item?.title}
                      </h1>
                      {hasSubItems ? (
                        item.type === 'array' ? (
                          subItemsIsObject ? (
                            <CustomDataTable
                              customItems={item?.child}
                              headers={subHeaders}
                              customHeaders={[
                                {
                                  name: 'Número',
                                  field: 'numero',
                                  sortable: true,
                                  element: item => {
                                    const fullNumber = `${item?.[LEMIT_COLUMN_NAME_DDD]}${item?.[LEMIT_COLUMN_NAME_NUMBER]}`
                                    return (
                                      <p>
                                        {item?.doNotDisturb && (
                                          <button
                                            className="btn py-0"
                                            style={{ cursor: 'default' }}
                                          >
                                            <span className="fa-solid fa-ban fa-xl text-danger"></span>
                                          </button>
                                        )}
                                        {phoneMask(fullNumber)}

                                        {!!item?.[
                                          LEMIT_COLUMN_NAME_WHATSAPP
                                        ] && (
                                          <button
                                            className="btn py-0"
                                            disabled={item?.doNotDisturb}
                                            onClick={() => {
                                              const a =
                                                document.createElement('a')
                                              a.href = `https://api.whatsapp.com/send?phone=${fullNumber}&text=`
                                              a.target = '_blank'
                                              a.click()
                                              a.remove()
                                            }}
                                          >
                                            <span
                                              className={`fab fa-whatsapp fa-xl ${
                                                item?.doNotDisturb
                                                  ? ''
                                                  : ' text-primary'
                                              }`}
                                            ></span>
                                          </button>
                                        )}
                                      </p>
                                    )
                                  }
                                }
                              ]}
                            />
                          ) : (
                            item?.child?.map((subItem: any) => {
                              return (
                                <div
                                  className="col"
                                  key={Math.random() * 1000 + 1}
                                >
                                  <p
                                    className="fw-bolder fs-6 text-gray-800"
                                    style={{
                                      whiteSpace: 'nowrap',
                                      textTransform: 'capitalize'
                                    }}
                                  >
                                    {subItem.value}
                                  </p>
                                </div>
                              )
                            })
                          )
                        ) : (
                          item?.child?.map((subItem: any) => {
                            return (
                              <div
                                className="col"
                                key={Math.random() * 1000 + 1}
                              >
                                <label
                                  className=" fw-bold fs-6"
                                  style={{ textTransform: 'capitalize' }}
                                >
                                  {subItem.title}
                                </label>
                                <p
                                  className="fw-bolder fs-6 text-gray-800"
                                  style={{ whiteSpace: 'nowrap' }}
                                >
                                  {subItem.value}
                                </p>
                              </div>
                            )
                          })
                        )
                      ) : (
                        <p
                          className="fw-bolder fs-6 text-gray-800"
                          style={{ textTransform: 'capitalize' }}
                        >
                          {item?.value}
                        </p>
                      )}
                    </div>
                  )
                }
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Dashboard
