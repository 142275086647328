import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Container from '../../../../components/Container'
import DataTable from '../../../../components/DataTable'
import { nameEntity, nameSource } from '../domain/info'
import { toolsList } from '../domain/tools'
import { breadcrumbList } from '../domain/breadcrumb'
import { headers, headersDashboardProspect } from '../domain/headers'
import moment from 'moment'
import { ContainerDashboard, FilterContainer } from './style'
import { Input, Select } from 'components/Form'
import {
  LOCAL_STORAGE_KEYS,
  MANAGER_ROLES,
  PROSPECT_STATUS
} from 'common/constants'
import { useLoading } from 'hooks/loading'
import api from 'services/api'
import Modal from 'components/Modal'
import { FormExport } from '../components/FormExport'
import { toolsExport } from '../domain/tools/list'
import { Datalist } from 'components/Datalist'
import { useLocation } from 'react-router-dom'
import { Loading } from 'components/Loading'
import { useAuth } from 'hooks/auth'
import CustomDataTable from 'components/CustomDataTable'
import { convertStringToBase64 } from 'utlis/base64'

type Filters = {
  id?: string
  status?: string
  name?: string
  agreement_group_id?: string
  cpf?: string
}
export interface CallbackDataTableProps {
  items: any[]
  params: any
  totalItems: number
}
export interface CallbackDataTableResponse {
  items: any[]
  params: any
  maxPage: number
  totalItems: number
}
export function callbackLoadDataProspect({
  items,
  totalItems,
  params
}: CallbackDataTableProps) {
  const getIds = items.map(item => item.id)
  sessionStorage.setItem(
    LOCAL_STORAGE_KEYS.PROSPECT_LIST,
    JSON.stringify({
      params,
      totalItems,
      maxPage: Math.ceil(totalItems / 50),
      items: getIds
    })
  )
}

const List: React.FC = () => {
  const { user } = useAuth()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const { activeLoading, disableLoading } = useLoading()
  const [statusValue, setStatusValue] = useState<string>('')
  const [cpfValue, setCpfValue] = useState<string>('')
  const [nameValue, setNameValue] = useState<string>('')
  const [prospectIdValue, setProspectIdValue] = useState<string>('')
  const [agreementGroupValue, setAgreementGroupValue] = useState({
    name: '',
    value: 0,
    id: 0
  })
  const [modalExportProspect, setModalExportProspect] = useState(false)
  const [openDataTable, setOpenDataTable] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [parameters, setParameters] = useState<any[] | undefined>([])
  const [partnerDashboard, setPartnerDashboard] = useState([])
  const [agreementGroups, setAgreementGroups] = useState<
    { id: number; value: number; name: string }[]
  >([{ id: 0, value: 0, name: '' }])
  const formatDateOut = 'DD/MM/YYYY'

  const getAgreementGroups = useCallback(async () => {
    activeLoading()
    const response = await api.get('/operational/agreementGroups')
    const agreementGroupsSelect = response.data?.map(
      (agreementGroup: { id: number; name: string }) => ({
        value: agreementGroup.id,
        name: agreementGroup.name,
        id: agreementGroup.id
      })
    )
    setAgreementGroups(agreementGroupsSelect)
    disableLoading()
  }, [activeLoading, disableLoading])

  const handleSetFilter = useCallback(
    (filter?: Filters) => {
      const filters = filter || {
        id: prospectIdValue || undefined,
        status: statusValue || undefined,
        name: nameValue || undefined,
        agreement_group_id: agreementGroupValue?.id || undefined,
        cpf: cpfValue || undefined
      }
      sessionStorage.setItem(
        LOCAL_STORAGE_KEYS.LIST_PROSPECTS_FILTERS,
        JSON.stringify(filters)
      )
      setParameters([
        {
          ...filters
        }
      ])
    },
    [agreementGroupValue?.id, cpfValue, nameValue, prospectIdValue, statusValue]
  )

  useEffect(() => {
    const storageFilters = sessionStorage.getItem(
      LOCAL_STORAGE_KEYS.LIST_PROSPECTS_FILTERS
    )
    const startDate = queryParams.get('start_date')
    const endDate = queryParams.get('end_date')
    let copyParams = {} as {
      id?: string
      status?: string
      name?: string
      start_date?: string
      end_date?: string
      agreement_group_id?: number
      cpf?: string
    }
    let open = false
    if (storageFilters) {
      copyParams = { ...JSON.parse(storageFilters) }

      setProspectIdValue(copyParams.id)
      setStatusValue(copyParams.status)
      setNameValue(copyParams.name)
      setAgreementGroupValue({
        id: copyParams.agreement_group_id,
        name: '',
        value: copyParams.agreement_group_id
      })
      setCpfValue(copyParams.cpf)
      open = true
    }
    if (startDate) {
      copyParams = {
        start_date: startDate
      }
      open = true
    }
    if (endDate) {
      copyParams = {
        ...copyParams,
        end_date: endDate
      }
      open = true
    }
    setParameters([copyParams])
    setOpenDataTable(open)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getAgreementGroups()
  }, [getAgreementGroups])

  const handleClickModalQueryOnClose = () => {
    setModalExportProspect(false)
  }

  const loadPartnerDashboard = useCallback(async () => {
    setIsLoading(true)
    try {
      const responsePartnerDashboard = await api.post(
        '/commercial/prospects/report/partnerDashboard'
      )
      setPartnerDashboard(responsePartnerDashboard.data)
    } catch (error) {}
    setIsLoading(false)
  }, [])

  useEffect(() => {
    if (MANAGER_ROLES.includes(user?.role_id)) return
    loadPartnerDashboard()
  }, [loadPartnerDashboard, user?.role_id])

  const toolsMount = useMemo(() => {
    let defaultTools = [
      ...toolsList,
      toolsExport(() => setModalExportProspect(true))
    ]
    if (user?.oldUser?.uid) {
      defaultTools = [...toolsList]
    }
    return defaultTools
  }, [user?.oldUser?.uid])
  if (!parameters || isLoading) {
    return <Loading isActive />
  }
  return (
    <Container
      pageTitle="Listagem"
      breadcrumb={breadcrumbList}
      tools={toolsMount}
    >
      {!MANAGER_ROLES.includes(user?.role_id) && (
        <ContainerDashboard>
          <h3>Meus resultados</h3>
          <CustomDataTable
            customItems={partnerDashboard}
            headers={headersDashboardProspect}
            actions={[
              {
                name: 'Filtrar',
                title: 'Filtrar',
                spanIcon: 'fa fa-filter',
                onClick: item => {
                  handleSetFilter({
                    agreement_group_id: item.agreement_group_id
                  })
                  setAgreementGroupValue({
                    id: item.agreement_group_id,
                    name: item.agreement_group_name,
                    value: item.agreement_group_id
                  })
                  setOpenDataTable(true)
                }
              }
            ]}
          />
        </ContainerDashboard>
      )}

      <FilterContainer>
        <div className="row">
          <Datalist
            data={agreementGroups}
            label="Grupo de Convênio"
            setValue={setAgreementGroupValue}
            value={agreementGroupValue}
          />
          <div className="col-md-3">
            <Select
              name="status"
              label="Status"
              id="status"
              value={statusValue}
              onChange={({ target }) => setStatusValue(target.value)}
              options={PROSPECT_STATUS}
              blank
            />
          </div>
          <div className="col-md-3">
            <Input
              name="cpf"
              label="CPF"
              id="cpf"
              onChange={({ target }) => setCpfValue(target.value)}
              value={cpfValue}
            />
          </div>
          <div className="col-md-3">
            <Input
              name="id"
              label="Cod"
              id="id"
              onChange={({ target }) => setProspectIdValue(target.value)}
              value={prospectIdValue}
            />
          </div>
          <div className="col-md-3">
            <Input
              name="name"
              label="Nome"
              id="name"
              onChange={({ target }) => setNameValue(target.value)}
              value={nameValue}
            />
          </div>
        </div>
        <footer>
          <button
            className="btn btn-light-primary"
            onClick={() => {
              setStatusValue('')
              setAgreementGroupValue({
                name: '',
                value: 0,
                id: 0
              })
              setParameters([])
              setOpenDataTable(false)
            }}
          >
            LIMPAR
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              handleSetFilter()
              setOpenDataTable(true)
            }}
          >
            BUSCAR
          </button>
        </footer>
        <div className="separator" />
      </FilterContainer>

      {openDataTable && (
        <DataTable
          entity={nameEntity}
          source={nameSource}
          format={{ orderBy: '' }}
          callbackLoadData={callbackLoadDataProspect}
          notHasChildren
          onlyView
          headers={headers}
          searchParameters={parameters}
          customHeaders={[
            {
              name: 'Status',
              field: 'status',
              sortable: false,
              element: item => {
                const prospectHistories = item?.prospectHistories
                let findLastHistoryStatus = item.status
                if (prospectHistories.length) {
                  const find = (prospectHistories as ProspectQueryApiResponse[])
                    .filter(item => {
                      if (user?.oldUser) {
                        return item.partner_id === user.oldUser?.uid
                      }
                      return item.created_by === Number(user.id)
                    })
                    .sort(
                      (currentItem, nextItem) => nextItem.id - currentItem.id
                    )
                    .find(item => {
                      if (user?.oldUser) {
                        return item.partner_id === user.oldUser?.uid
                      }
                      return item.created_by === Number(user.id)
                    })
                  if (find) {
                    findLastHistoryStatus = find.status
                  }
                }
                return <p>{findLastHistoryStatus || ''}</p>
              }
            },
            {
              name: 'Data de Nascimento',
              field: 'birth_date',
              sortable: true,
              element: item => (
                <p>
                  {item.birth_date
                    ? moment(item.birth_date, formatDateOut).format(
                        formatDateOut
                      )
                    : ''}
                </p>
              )
            },
            {
              name: 'Data de Admissão',
              field: 'admission_date',
              sortable: true,
              element: item => (
                <p>
                  {item.admission_date
                    ? moment(item.admission_date, formatDateOut).format(
                        formatDateOut
                      )
                    : ''}
                </p>
              )
            }
          ]}
          actions={[
            {
              name: 'view',
              title: 'Visualizar',
              spanIcon: 'fa fa-search',
              linkTo: item => {
                return `/commercial/prospects/view/${convertStringToBase64(
                  item.id?.toString()
                )}`
              }
            }
          ]}
        />
      )}

      {modalExportProspect && (
        <Modal
          onClickButtonCancel={handleClickModalQueryOnClose}
          isOpenModal={modalExportProspect}
          pageTitle="Exportar prospects"
          styles={{ padding: '30px 5px', overflow: 'auto' }}
          Children={
            <FormExport
              isOpenInModal={{ handleOnClose: handleClickModalQueryOnClose }}
            />
          }
        />
      )}
    </Container>
  )
}
export default List
