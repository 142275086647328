import { Buffer } from 'buffer'

/**
 * Converts a string to its Base64 representation.
 * @param {string} input - The string to be converted to Base64.
 * @returns {string} - The Base64 encoded string.
 * @throws {TypeError} - If the input is not a string.
 * @throws {Error} - If the string is empty.
 */
export function convertStringToBase64(value: string): string {
  if (typeof value !== 'string') {
    return value
  }

  if (value.trim() === '') {
    return value
  }

  try {
    return Buffer.from(value, 'utf-8').toString('base64')
  } catch (error: any) {
    throw new Error(`Failed to convert string to Base64: ${error.message}`)
  }
}

/**
 * Decodes a Base64 encoded string back to its original string representation.
 * @param {string} base64 - The Base64 encoded string to be decoded.
 * @returns {string} - The decoded string, or an empty string if the input is invalid.
 */
export function convertBase64ToString(base64: string): string {
  // Validate that the input is a string
  if (typeof base64 !== 'string') {
    return ''
  }

  // Validate that the string is not empty
  if (base64.trim() === '') {
    return ''
  }

  try {
    // Decode the Base64 string
    return Buffer.from(base64, 'base64').toString('utf-8')
  } catch {
    // Return an empty string on failure
    return ''
  }
}
